import React from 'react';
import '../../HeroSection.css';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';

function Faq() {
  return (
    <>
      <div className='content'>
        <div className='container'>
          <div className='row home__hero-row'>
            <div className='col'>
              <div className='home__hero-text-wrapper'>
                <div className='top-line content'>
                  FAQ
                </div>
                <p>
                  <Accordion>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                              Does OnPoint IPTV contain any channels?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                            No! OnPoint IPTV will not help you to find a good playlist. We also do not provide playlists. You have to create your playlist yourself. You can find a help on our website. We are also not responsible for the content uploaded to our app. Please do not activate the app if you do not have a playlist for the app, because after activation the app will not contain any channels. Your payment will not be refunded if you activate the app without having a playlist for the app or if something does not work.</p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                              Why is the app freezing on start?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                              Because the app hast to load the playlist from your provider's server every time you start it.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                              Can i change the background image of the App?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                              Yes, you can find more themes under "Settings" of the app. There are 5 themes available for you!
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                             Why can I not start the app?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                              This may be because of your internet connection. Please check your internet connection first before contacting your provider.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                              Do the OnPoint IPTV have an EPG-System?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                              Yes, we have our own EPG system. Please enter all EPG-ID's in the playlist so that you can see all EPG.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </p>
              </div>
            </div>
            <div className='col'>
              <div className='home__hero-img-wrapper'>
              </div>
            </div>
          </div>
          <div className='row home__hero-row'> 
          </div>
        </div>
      </div>
    </>
  );
}

export default Faq;
