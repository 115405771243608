import React from "react";
import "../../HeroSection.css";

import "react-accessible-accordion/dist/fancy-example.css";

function Contact() {
  return (
    <>
      <div className="content">
        <div className="container">
          <div className="row home__hero-row">
            <div className="col">
              <div className="home__hero-text-wrapper">
                <div className="top-line content">Contact</div>
                <p>
                  <br />
                  E-Mail: info@isi-soft.com
                  <br />
                  <br />
                  <a href="https://t.me/onpointiptv">Telegram</a>
                  <br />
                </p>
              </div>
            </div>
            <div className="col">
              <div className="home__hero-img-wrapper"></div>
            </div>
          </div>
          <div className="row home__hero-row"></div>
        </div>
      </div>
    </>
  );
}

export default Contact;
