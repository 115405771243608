import React from "react";
import "./Pricing.css";
import { IconContext } from "react-icons/lib";
import { Link } from "react-router-dom";
import { IoIosColorPalette } from "react-icons/io";
import { FaLock, FaLayerGroup } from "react-icons/fa";
import { GoListOrdered } from "react-icons/go";
import { MdLocalMovies, MdSubtitles } from "react-icons/md";
import ReactPlayer from "react-player";

function Pricing() {
  return (
    <IconContext.Provider value={{ color: "#fff", size: 64 }}>
      <div className="pricing__section">
        <div className="pricing__wrapper">
          <h1 className="pricing__heading">Main Features</h1>
          <div className="pricing__container">
            <Link to="#" className="pricing__container-card">
              <div className="pricing__container-cardInfo">
                <div className="icon">
                  <MdSubtitles />
                </div>
                <h3>EPG & Subtitles</h3>
                <p>EPG-System and Subtitles</p>
              </div>
            </Link>
            <Link to="#" className="pricing__container-card">
              <div className="pricing__container-cardInfo">
                <div className="icon">
                  <IoIosColorPalette />
                </div>
                <h3>Custom Design</h3>
                <p>Change the app color the way you like it</p>
              </div>
            </Link>
            <Link to="#" className="pricing__container-card">
              <div className="pricing__container-cardInfo">
                <div className="icon">
                  <FaLock />
                </div>
                <h3>Protection</h3>
                <p>Password Lock for Channels</p>
              </div>
            </Link>
          </div>
          <div className="pricing__container">
            <Link to="#" className="pricing__container-card">
              <div className="pricing__container-cardInfo">
                <div className="icon">
                  <GoListOrdered />
                </div>
                <h3>Mutiple Playlists</h3>
                <p>Upload multiple playlists and switch them in the App</p>
              </div>
            </Link>
            <Link to="#" className="pricing__container-card">
              <div className="pricing__container-cardInfo">
                <div className="icon">
                  <MdLocalMovies />
                </div>
                <h3>Different Modes</h3>
                <p>Separate Area for Live TV, Series and Movies</p>
              </div>
            </Link>
            <Link to="#" className="pricing__container-card">
              <div className="pricing__container-cardInfo">
                <div className="icon">
                  <FaLayerGroup />
                </div>
                <h3>Create custom Groups</h3>
                <p>Create custom groups with your channels</p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </IconContext.Provider>
  );
}
export default Pricing;
