import React, { useEffect } from "react";
import { Textbox } from "react-inputs-validation";
import { Button } from "../../Button";
import $ from "jquery";
import "react-inputs-validation/lib/react-inputs-validation.min.css";
import "../../HeroSection.css";
import { PayPalButton } from "react-paypal-button-v2";

function Activation() {
  useEffect(() => {
    $(".paypal-buttons").hide();
  });

  function clear() {
    $(".paypal-buttons").hide();
  }

  function paypalBuy() {
    var macInput = $("#macInput").val();
    var emailInput = $("#emailInput").val();

    if (macInput && emailInput) {
      $.ajax({
        url: "php/checkMac.php",
        method: "post",
        data: {
          mac: macInput,
        },
        success: function (data) {
          $(".addMacInfo").text(
            "Your MAC/LG-ID Address exist! You can activate the App for this Device"
          );
          $(".addMacInfo").show();
          $(".paypal-buttons").show();
        },
        error: function (data) {
          $(".addMacInfo").text(
            "This MAC does not exist. Please open the app and enter the MAC/LG-ID that is displayed in the App."
          );
          $(".addMacInfo").show();
          $(".paypal-buttons").hide();
        },
      });
    } else {
      $(".addMacInfo").text("Please enter your Email and MAC/LG-ID");
      $(".addMacInfo").css("display", "block");
      $(".paypal-buttons").hide();
    }
  }

  return (
    <>
      <div className="content">
        <div className="container">
          <div className="row home__hero-row">
            <div className="col">
              <div className="home__hero-text-wrapper">
                <div className="top-line content">
                  <div className="col">
                    <div className="home__hero-img-wrapper">
                      <div className="row home__hero-row mainWrapper">
                        <div class="textInput3"></div>
                      </div>
                    </div>
                  </div>
                  <div className="text"> Click here, to activate the App</div>
                  <a href="https://isidev-shop.company.site/OnPoint-App-Activation-p524586664">
                    <button class="btn btn--white btn--animated">
                      App Activation
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Activation;
