import React from 'react';
import '../../HeroSection.css';

function Privatepolicy() {

  return (
    <>
      <div className='home__hero-section'>
        <div className='container'>
          <div className='row home__hero-row'
            style={{
              display: 'flex',
              flexDirection: 'start'
            }}
          >
            <div className='col'>
              <div className='home__hero-text-wrapper'>
                <div className='top-line'>
                Privacy Policy
                </div>
                <h1 className='heading dark'>  
                </h1>
                <p className='home__hero-subtitle dark'>
                Effective date: December 09, 2020
                <br/><br/>
                OnPoint IPTV ("us", "we", or "our") operates the OnPoint IPTV mobile/tv application (the "Service").
                <br/><br/>
                This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.
                <br/><br/>
                We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.
                <br/><br/>  
                </p>
                <h1 className='heading dark'>  
                Information Collection And Use
                </h1>
                <p className='home__hero-subtitle dark'>
                We collect several different types of information for various purposes to provide and improve our Service to you.
                </p>
                <h1 className='heading dark'>  
                Types of Data Collected
                </h1>
                <p className='home__hero-subtitle dark'>
                Personal Data<br/><br/> 
                While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:
                <br/>
                - Email address<br/>
                - Cookies and Usage Data
                </p>
                <h1 className='heading dark'>  
                Usage Data
                </h1>
                <p className='home__hero-subtitle dark'>
                When you access the Service by or through a mobile device, we may collect certain information automatically, including, but not limited to, the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers and other diagnostic data ("Usage Data").
                </p>
                <h1 className='heading dark'>  
                Tracking & Cookies Data
                </h1>
                <p className='home__hero-subtitle dark'>
                We use cookies and similar tracking technologies to track the activity on our Service and hold certain information.
                <br/><br/>
                Cookies are files with small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Tracking technologies also used are beacons, tags, and scripts to collect and track information and to improve and analyze our Service.
                <br/><br/>
                You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.
                <br/><br/>
                Examples of Cookies we use:
                <br/><br/>
                -Session Cookies. We use Session Cookies to operate our Service.<br/>
                -Preference Cookies. We use Preference Cookies to remember your preferences and various settings.<br/>
                -Security Cookies. We use Security Cookies for security purposes.   <br/>             
                </p>
                <h1 className='heading dark'>  
                Use of Data
                </h1>
                <p className='home__hero-subtitle dark'>
                OnPoint IPTV uses the collected data for various purposes:<br/><br/>

                -To provide and maintain the Service<br/>
                -To notify you about changes to our Service<br/>
                -To allow you to participate in interactive features of our Service when you choose to do so<br/>
                -To provide customer care and support<br/>
                -To provide analysis or valuable information so that we can improve the Service<br/>
                -To monitor the usage of the Service<br/>
                -To detect, prevent and address technical issues        <br/>    
                </p>
                <h1 className='heading dark'>  
                Transfer Of Data
                </h1>
                <p className='home__hero-subtitle dark'>
                Your information, including Personal Data, may be transferred to - and maintained on - computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.
                <br/><br/>
                If you are located outside Germany and choose to provide information to us, please note that we transfer the data, including Personal Data, to Germany and process it there.
                <br/><br/>
                Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.
                <br/><br/>
                OnPoint IPTV will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.
                </p>
                <h1 className='heading dark'>  
                Disclosure Of Data
                </h1>
                <p className='home__hero-subtitle dark'>
                Legal Requirements
                <br/><br/>
                -To comply with a legal obligation<br/>
                -To protect and defend the rights or property of OnPoint IPTV<br/>
                -To prevent or investigate possible wrongdoing in connection with the Service<br/>
                -To protect the personal safety of users of the Service or the public<br/>
                -To protect against legal liability<br/>
                </p>
                <h1 className='heading dark'>  
                Security Of Data
                </h1>
                <p className='home__hero-subtitle dark'>
                The security of your data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.
                </p>
                <h1 className='heading dark'>  
                Service Providers
                </h1>
                <p className='home__hero-subtitle dark'>
                We may employ third party companies and individuals to facilitate our Service ("Service Providers"), to provide the Service on our behalf, to perform Service-related services or to assist us in analyzing how our Service is used.
                <br/><br/>
                These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.
                </p>
                <h1 className='heading dark'>  
                Links To Other Sites
                </h1>
                <p className='home__hero-subtitle dark'>
                Our Service may contain links to other sites that are not operated by us. If you click on a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.
                <br/><br/>
                We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.
                </p>
                <h1 className='heading dark'>  
                Children's Privacy
                </h1>
                <p className='home__hero-subtitle dark'>
                Our Service does not address anyone under the age of 18 ("Children").<br/><br/>
                We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your Children has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers.
                </p>
                <h1 className='heading dark'>  
                Use and application of PayPal
                </h1>
                <p className='home__hero-subtitle dark'>
                OnPoint IPTV has integrated components of PayPal on this website. PayPal is an online payment service provider. Payments are processed through PayPal accounts, which are virtual private or business accounts. In addition, PayPal offers the possibility to make virtual payments by credit card if a user does not have a PayPal account. A PayPal account is managed via an e-mail address, which is why there is no classic account number. PayPal makes it possible to trigger online payments to third parties or to receive payments. PayPal also assumes trustee functions and offers buyer protection services.
                <br/><br/>
                The European operating company of PayPal is PayPal (Europe) S.à.r.l. & Cie S.C.A., 22-24 Boulevard Royal, 2449 Luxembourg, Luxembourg.
                <br/><br/>
                If the person concerned selects "PayPal" as a payment option during the ordering process in our online shop, data of the person concerned is automatically transmitted to PayPal. By selecting this payment option, the person concerned consents to the transmission of personal data required for payment processing.
                <br/><br/>
                The personal data transmitted to PayPal is usually first name, surname, address, e-mail address, IP address, telephone number, mobile phone number or other data necessary for the processing of payment. Personal data that are necessary for the processing of the purchase contract are also those that are related to the respective order.
                <br/><br/>
                The transmission of the data is intended for payment processing and fraud prevention. The person responsible for processing will transmit personal data to PayPal in particular if there is a legitimate interest in the transmission. Personal data exchanged between PayPal and the data controller may be transmitted by PayPal to credit reference agencies. The purpose of this transmission is to check identity and creditworthiness.
                <br/><br/>
                PayPal may share the personal data with affiliated companies and service providers or subcontractors to the extent necessary for the performance of the contractual obligations or where the data are to be processed under contract.
                <br/><br/>
                The person concerned has the possibility to revoke his or her consent to PayPal's handling of personal data at any time. Revocation does not affect personal data that must be processed, used or transmitted for the (contractual) handling of payments.
                <br/><br/>
                PayPal's applicable data protection regulations can be viewed at https://www.paypal.com/en/webapps/mpp/ua/privacy-full.
                </p>
                <h1 className='heading dark'>  
                Amazon Partner Program
                </h1>
                <p className='home__hero-subtitle dark'>
                As the operator of this website we participate in the Amazon EU Partner Program. On our pages, we include advertisements from Amazon as well as links to Amazon in order to earn money through advertising cost reimbursements. Amazon cookies are used, which allow Amazon to recognize that you have clicked on an affiliate link on our site.
                <br/><br/>
                The storage of "Amazon cookies" is based on Art. 6 lit. f DSGVO. As the website operator, we have a legitimate interest in this, as the amount of the advertising cost reimbursement can only be determined through the cookies.
                <br/><br/>
                Details on the use of data by Amazon can be found in the Amazon Privacy Policy: https://www.amazon.de/gp/help/customer/display.html/ref=footer_privacy?ie=UTF8&nodeId=3312401.
                </p>
                <h1 className='heading dark'>  
                Klarna
                </h1>
                <p className='home__hero-subtitle dark'>
                Our website allows payment via Klarna. The payment service is provided by Klarna AB, Sveavägen 46, 111 34 Stockholm, Sweden.
                <br/><br/>
                When paying via Klarna (Klarna checkout solution), Klarna collects various personal information from you. Details can be found in Klarna's privacy policy at: https://www.klarna.com/de/datenschutz/.
                <br/><br/>
                Klarna uses cookies to optimize the Klarna checkout solution. This optimization constitutes a legitimate interest in the sense of Art. 6 para. 1 lit. f DSGVO. Cookies are small text files that your web browser stores on your end device. Cookies from Klarna remain on your terminal device until you delete them. Details on the use of Klarna cookies can be found at: https://cdn.klarna.com/1.0/shared/content/policy/cookie/de_de/checkout.pdf.
                <br/><br/>
                The transmission of your data to Klarna is based on art. 6 par. 1 lit. a DSGVO (consent) and art. 6 par. 1 lit. b DSGVO (processing for the performance of a contract). A revocation of your already given consent is possible at any time. Data processing procedures in the past remain effective in the event of a revocation.
                </p>
                <h1 className='heading dark'>  
                Google Web Fonts
                </h1>
                <p className='home__hero-subtitle dark'>
                Our website uses Web Fonts from Google. The provider is Google Inc, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.
                <br/><br/>
                By using these Web Fonts it is possible to present you with the presentation of our website that we desire, regardless of which fonts are available locally. This is done by retrieving the Google Web Fonts from a Google server in the USA and the associated transfer of your data to Google. This is your IP address and which page you have visited on our website. The use of Google Web Fonts is based on Art. 6 para. 1 lit. f DSGVO. As the operator of this website, we have a legitimate interest in the optimal display and transmission of our web presence.
                <br/><br/>
                The company Google is certified for the us-European data protection convention "Privacy Shield". This data protection agreement is intended to ensure compliance with the level of data protection applicable in the EU.
                <br/><br/>
                Details about Google Web Fonts can be found at: https://www.google.com/fonts#AboutPlace:about and further information can be found in Google's privacy policy: https://policies.google.com/privacy/partners?hl=de
                </p>
                <h1 className='heading dark'>  
                Embedded YouTube videos
                </h1>
                <p className='home__hero-subtitle dark'>
                Our website uses Web Fonts from Google. The provider is Google Inc, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.
                <br/><br/>
                On some of our websites we embed YouTube videos. The operator of the corresponding plug-ins is YouTube, LLC, 901 Cherry Ave., San Bruno, CA 94066, USA. When you visit a page with the YouTube plug-in, a connection to YouTube servers is established. This tells YouTube which pages you visit. If you are logged in to your YouTube account, YouTube can personally associate your browsing behavior with you. You can prevent this by first logging out of your YouTube account.
                <br/><br/>
                If a YouTube video is started, the provider uses cookies that collect information about user behavior.
                <br/><br/>
                If you have deactivated the storage of cookies for the Google Ad program, you will not have to expect such cookies when watching YouTube videos. YouTube also stores non-personal usage information in other cookies. If you want to prevent this, you must block the storage of cookies in your browser.
                <br/><br/>
                YouTube, YouTube LLC, 901 Cherry Ave, San Bruno, CA 94066, USA (Privacy Policy)
                </p>
                <h1 className='heading dark'>  
                Server log files
                </h1>
                <p className='home__hero-subtitle dark'>
                In server log files, the provider of the website automatically collects and stores information that your browser automatically sends to us. These are:
                <br/><br/>
                Visited page on our domain Date and time of the server request Browser type and version Operating system used Referrer URL Hostname of the accessing computer IP address This data is not merged with other data sources. The basis for data processing is Art. 6 para. 1 lit. b DSGVO, which permits the processing of data for the fulfilment of a contract or pre-contractual measures.
                </p>
                <h1 className='heading dark'>  
                Changes To This Privacy Policy
                </h1>
                <p className='home__hero-subtitle dark'>
                In server log files, the provider of the website automatically collects and stores information that your browser automatically sends to us. These are:
                <br/><br/>
                We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
                <br/><br/>
                We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the "effective date" at the top of this Privacy Policy.
                <br/><br/>
                You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
                </p>
                <h1 className='heading dark'>  
                Contact Us
                </h1>
                <p className='home__hero-subtitle dark'>
                If you have any questions about this Privacy Policy, please contact us:
                <br/><br/>
                By email: support@onpointiptvapp.com                
                </p>

              </div>
            </div>
            <div className='col'>
              <div className='home__hero-img-wrapper'>
              </div>
            </div>
          </div>
          <div className='row home__hero-row'> 
            
          </div>
        </div>
      </div>
    </>
  );
}

export default Privatepolicy;
