import React from 'react';
import { Button } from '../../Button';
import { Link } from 'react-router-dom';
import '../../HeroSection.css';
import './style.css';


function Besttvs() {

  return (
    <>
      <div className='home__hero-section'>
        <div className='container'>
          <div className='row home__hero-row'
            style={{
              display: 'flex',
              flexDirection: 'start'
            }}
          >
            <div className='col'>
              <div className='home__hero-text-wrapper'>
                <div className='top-line'>
                Top Smart TVs for IPTV!
                </div>
                <h1 className='heading dark'>  
                  Best Performance
                </h1>
                <div className='top-line'>
                
                </div>
                <p className='home__hero-subtitle dark'>
                  <div class="card">
                    <div class="card-head">
                      <div class="product-detail">Samsung TV
                      </div>
                      <span class="back-text">
                      </span>
                    </div>
                    <div class="card-body">
                      <div class="product-desc">
                        <span class="product-title">
                          Samsung TV QLED 4K Q90T
                          <span class="badge">
                            New
                          </span>
                        </span>
                        <span class="product-caption">
                          <a href="https://www.amazon.de/Samsung-GQ65Q90TGTXZG-Quantum-Prozessor-Direct/dp/B085L9FJV8/ref=as_li_ss_il?__mk_de_DE=%C3%85M%C3%85%C5%BD%C3%95%C3%91&dchild=1&keywords=samsung+q90&qid=1608835920&sr=8-7&linkCode=li2&tag=isisoftwebsit-21&linkId=1709ee11d02bc199a93ff1ffa8efb01e&language=de_DE" target="_blank"><img border="0" src="//ws-eu.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B085L9FJV8&Format=_SL160_&ID=AsinImage&MarketPlace=DE&ServiceVersion=20070822&WS=1&tag=isisoftwebsit-21&language=de_DE" /></a><img src="https://ir-de.amazon-adsystem.com/e/ir?t=isisoftwebsit-21&language=de_DE&l=li2&o=3&a=B085L9FJV8" width="1" height="1" border="0" alt="" />
                        </span>
                        <span className='margin-left'>
                          <Link to='//amzn.to/3hkJPun' target="_blank" >
                            <Button buttonSize='btn--wide' buttonColor='blue'>Visit</Button>
                          </Link>
                        </span>
                        </div>
                        <span class="product-rating">
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star grey"></i>
                        </span>
                      </div>
                      <div class="product-properties">
                        
                    </div>
                  </div>

                  <h1 className='heading dark'>  
                  Best Budget
                </h1>
                <div className='top-line'>
                
                </div>
                  <div class="card">
                    <div class="card-head">
                      <div class="product-detail">Samsung TV
                      </div>
                      <span class="back-text">
                      </span>
                    </div>
                    <div class="card-body">
                      <div class="product-desc">
                        <span class="product-title">
                          Samsung TV QLED 4K Q80T
                          <span class="badge">
                            New
                          </span>
                        </span>
                        <span class="product-caption">
                          <a href="https://www.amazon.de/Samsung-Quantum-Prozessor-Direct-Modelljahr/dp/B0866H2DG9/ref=as_li_ss_il?__mk_de_DE=%C3%85M%C3%85%C5%BD%C3%95%C3%91&dchild=1&keywords=samsung+q90&qid=1608836718&sr=8-2&linkCode=li2&tag=isisoftwebsit-21&linkId=854b95b79337925d9a244412284ac912&language=de_DE" target="_blank"><img border="0" src="//ws-eu.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B0866H2DG9&Format=_SL160_&ID=AsinImage&MarketPlace=DE&ServiceVersion=20070822&WS=1&tag=isisoftwebsit-21&language=de_DE" /></a><img src="https://ir-de.amazon-adsystem.com/e/ir?t=isisoftwebsit-21&language=de_DE&l=li2&o=3&a=B0866H2DG9" width="1" height="1" border="0" alt=""/>                        
                        </span>
                        <span className='margin-left'>
                          <Link to='//amzn.to/3heTRNL'  target="_blank">
                            <Button buttonSize='btn--wide' buttonColor='blue'>Visit</Button>
                          </Link>
                        </span>
                        </div>
                        <span class="product-rating">
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star grey"></i>
                        </span>
                      </div>
                      <div class="product-properties">
                        
                    </div>
                  </div>
                  <h1 className='heading dark'>  
                  Most popular
                </h1>
                <div className='top-line'>
                
                </div>
                  <div class="card">
                    <div class="card-head">
                      <div class="product-detail">LG TV
                      </div>
                      <span class="back-text">
                      </span>
                    </div>
                    <div class="card-body">
                      <div class="product-desc">
                        <span class="product-title">
                          LG OLED55CX9LA OLED
                          <span class="badge">
                            New
                          </span>
                        </span>
                        <span class="product-caption">
                          <a href="https://www.amazon.de/LG-OLED55CX9LA-139cm-Smart-Fernseher/dp/B087N8VNGR/ref=as_li_ss_il?__mk_de_DE=%C3%85M%C3%85%C5%BD%C3%95%C3%91&dchild=1&keywords=lg+oled55cx9la&qid=1608837228&s=ce-de&sr=1-3&linkCode=li2&tag=isisoftwebsit-21&linkId=ec912ca758675c2bffb70fee89ae1948&language=de_DE" target="_blank"><img border="0" src="//ws-eu.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B087N8VNGR&Format=_SL160_&ID=AsinImage&MarketPlace=DE&ServiceVersion=20070822&WS=1&tag=isisoftwebsit-21&language=de_DE" /></a><img src="https://ir-de.amazon-adsystem.com/e/ir?t=isisoftwebsit-21&language=de_DE&l=li2&o=3&a=B087N8VNGR" width="1" height="1" border="0" alt="" />             
                        </span>
                        <span className='margin-left'>
                          <Link to='//amzn.to/2JguAGe'  target="_blank">
                            <Button buttonSize='btn--wide' buttonColor='blue'>Visit</Button>
                          </Link>
                        </span>
                        </div>
                        <span class="product-rating">
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star grey"></i>
                        </span>
                      </div>
                      <div class="product-properties">
                        
                    </div>
                  </div>

                  <h1 className='heading dark'>  
                  Cheap
                </h1>
                <div className='top-line'>
                
                </div>
                  <div class="card">
                    <div class="card-head">
                      <div class="product-detail">Samsung TV
                      </div>
                      <span class="back-text">
                      </span>
                    </div>
                    <div class="card-body">
                      <div class="product-desc">
                        <span class="product-title">
                          Samsung TV GU43
                          <span class="badge">
                            New
                          </span>
                        </span>
                        <span class="product-caption">
                          <a href="https://www.amazon.de/dp/B08MX9HZLM/ref=as_li_ss_il?&smid=AO9LB2O5B5F9V&ascsub&linkCode=li2&tag=isisoftwebsit-21&linkId=ef0d7f82d6fe1bc6d401338176a6bbc4&language=de_DE" target="_blank"><img border="0" src="//ws-eu.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B08MX9HZLM&Format=_SL160_&ID=AsinImage&MarketPlace=DE&ServiceVersion=20070822&WS=1&tag=isisoftwebsit-21&language=de_DE" /></a><img src="https://ir-de.amazon-adsystem.com/e/ir?t=isisoftwebsit-21&language=de_DE&l=li2&o=3&a=B08MX9HZLM" width="1" height="1" border="0" alt="" />
                        </span>
                        <span className='margin-left'>
                          <Link to='//amzn.to/3aGfZPV'  target="_blank">
                            <Button buttonSize='btn--wide' buttonColor='blue'>Visit</Button>
                          </Link>
                        </span>
                        </div>
                        <span class="product-rating">
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star grey"></i>
                        </span>
                      </div>
                      <div class="product-properties">
                        
                    </div>
                  </div>
              
                </p>
              </div>
              <div className='home__hero-img-wrapper dark'>
              </div>
            </div>
            <div className='col'>
              <div className='home__hero-img-wrapper dark'>
              </div>
            </div>
          </div>
          <div className='row home__hero-row dark'> 
            
          </div>
        </div>
      </div>
    </>
  );
}

export default Besttvs;
