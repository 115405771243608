import React from "react";
import "./App.css";
import Home from "./components/pages/HomePage/Home";
import Uploadlist from "./components/pages/Uploadlist/Uploadlist";
import Faq from "./components/pages/FAQ/Faq";
import Complete from "./components/pages/Complete/Complete";
import Termsofservice from "./components/pages/Termsofservice/Termsofservice";
import Howitworks from "./components/pages/Howitworks/Howitworks";
import Privatepolicy from "./components/pages/Privatepolicy/Privatepolicy";
import Legal from "./components/pages/Legal/Legal";
import Contact from "./components/pages/Contact/Contact";
import Activation from "./components/pages/Activation/Activation";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/pages/Footer/Footer";
import Besttvs from "./components/pages/Besttvs/Besttvs";
import Playlists from "./components/pages/Playlists/Playlists";
import TurkishList from "./components/pages/TurkishList/TurkishList";
import TurkishChannels from "./components/pages/TurkishChannels/TurkishChannels";
import AlbaniaList from "./components/pages/AlbaniaList/AlbaniaList";

function App() {
  return (
    <Router>
      <Navbar {...{ img: "images/op_logo.png", alt: "test" }} />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/uploadlist" component={Uploadlist} />
        <Route path="/faq" component={Faq} />
        <Route path="/activation" component={Activation} />
        <Route path="/complete" component={Complete} />
        <Route path="/termsofservice" component={Termsofservice} />
        <Route path="/privatepolicy" component={Privatepolicy} />
        <Route path="/howitworks" component={Howitworks} />
        <Route path="/legal" component={Legal} />
        <Route path="/Contact" component={Contact} />
        <Route path="/Besttvs" component={Besttvs} />
        <Route path="/playlists" component={Playlists} />
        <Route path="/turkishList" component={TurkishList} />
        <Route path="/turkishChannels" component={TurkishChannels} />
        <Route path="/albaniaList" component={AlbaniaList} />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
