import React from 'react';
import { Textbox } from 'react-inputs-validation';
import { Button } from '../../Button';
import $ from "jquery";
import 'react-inputs-validation/lib/react-inputs-validation.min.css';
import '../../HeroSection.css';

function Uploadlist() {

  function uploadList(e){
    e.preventDefault();

    var macInput = $("#macInput").val();
    var urlInput = $("#urlInput").val();
    var urlInput2 = $("#urlInput2").val();
    var urlInput3 = $("#urlInput3").val();

    if(macInput && urlInput){
        $.ajax({
            url: 'php/addMac.php',
            method: 'post',
            data:{
                mac: macInput,
                url: urlInput,
                url2: urlInput2,
                url3: urlInput3
            },
            success: function (data) {
              $(".addMacInfo").text('Successfully Uploaded Playlist!');
              alert("Successfully Uploaded Playlist");
            },
            error: function (data) {
              $(".addMacInfo").text('Error: Your MAC address is not activated!');
              alert('Error: Your MAC address is not activated!');
            }
        });
    }else{
      $(".addMacInfo").text('Please enter your mac and url!');
      alert('Please enter your mac and url!');
    }
  }

  function deleteList(e) {
    e.preventDefault();

    var macInput = $("#deleteMac").val();

    if(macInput){
        $.ajax({
            url: 'php/deleteMac.php',
            method: 'post',
            data:{
                mac: macInput
            },
            success: function (data) {
                $(".addMacInfo2").text("Successfully Deleted Playlist");
                alert("Successfully Deleted Playlist");
              },
            error: function (data) {
                $(".addMacInfo2").text("Error! Something went wrong!!");
                alert("Error! Something went wrong!");
            }
        });
    }else{
        $(".addMacInfo2").text("error mac/url");
        alert("error mac/url");
    }
  }

  return (
    <>
    <div className='content'>
      <div className='container'>
        <div className='row home__hero-row'>
          <div className='col'>
            <div className='home__hero-text-wrapper'>
              <div className='top-line content'>
                <div className='text'>Upload Playlist</div>
                <div className='col'>
                  <div className='home__hero-img-wrapper'>
                    <div className='row home__hero-row'> 
                      <div class="textInput">MAC or LG-ID</div>
                      <Textbox
                        attributesInput={{
                          id: 'macInput',
                          name: 'MAC or LG-ID',
                          type: 'text',
                          placeholder: '',
                        }}
                      />
                      <div class="textInput">Playlist 1-Url</div>
                      <Textbox
                        attributesInput={{
                          id: 'urlInput',
                          name: 'MAC or LG-ID',
                          type: 'text',
                          placeholder: '',
                        }}
                      />
                      <div class="textInput">Playlist 2-Url</div>
                      <Textbox
                        attributesInput={{
                          id: 'urlInput2',
                          name: 'MAC or LG-ID',
                          type: 'text',
                          placeholder: '',
                        }}
                      />
                      <div class="textInput">Playlist 3-Url</div>
                      <Textbox
                        attributesInput={{
                          id: 'urlInput3',
                          name: 'MAC or LG-ID',
                          type: 'text',
                          placeholder: '',
                        }}
                      />
                      <Button buttonSize='btn--wide' buttonColor='blue' onClick={uploadList}>
                        Upload Playlist
                      </Button>
                      <div class="addMacInfo textInput"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col'>
            <div className='home__hero-text-wrapper'>
              <div className='top-line content'>

                <div class="textInput">MAC or LG-ID</div>
                <Textbox
                  attributesInput={{
                    id: 'deleteMac',
                    name: 'MAC or LG-ID',
                    type: 'text',
                    placeholder: '',
                  }}
                />

                <Button buttonSize='btn--wide' buttonColor='blue' onClick={deleteList}>
                  Delete Playlist
                </Button>
                <div class="addMacInfo2 textInput"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  );
}

export default Uploadlist;
