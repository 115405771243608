import React from 'react';
import '../../HeroSection.css';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';

function TurkishChannels() {
  return (
    <>
      <div className='content'>
        <div className='container'>
          <div className='row home__hero-row'>
            <div className='col'>
              <div className='home__hero-text-wrapper'>
                <div className='top-line content'>
                  Turkish Playlist Channellist
                </div>
                <p>
                <h4>Over 100 channels</h4>
ATV<br />
Show TV<br />
TRT Türk<br />
TRT World<br />
TRT Avaz<br />
TRT Belgesel<br />
TRT Çocuk<br />
TV4 HD Türkiye<br />
TVnet<br />
NTV<br />
Tempo TV<br />
Ton TV<br />
TLC TR<br />
Star TV<br />
TRT Haber<br />
TRT 1<br />
TRT 3<br />
TV 38<br />
TV 41<br />
TV 52<br />
TV A<br />
UçanKuş TV<br />
Vizyon TV<br />
Vizyon 58 TV<br />
VTV<br />
Yesilyurt TV<br />
YOL TV<br />
24 TV<br />
Afyon Turk TV<br />
Ahi TV Kirsehir<br />
Akilli TV<br />
AKIT TV<br />
Aksu TV<br />
Altas TV<br />
Aras TV<br />
ASTV<br />
ATR Kırım Tatar<br />
Av TV<br />
Berat TV<br />
Besiktas Web TV<br />
BLT Turk<br />
BRT 1 HD<br />
BRT 2<br />
BRT 2 (Turkish)<br />
BRTV<br />
Bursa TV<br />
Can TV<br />
Cay TV<br />
Cekmeköy TV<br />
Ciftci Tv<br />
DIM TV<br />
DRT TV<br />
Ege A Turk TV<br />
Em TV<br />
ER TV<br />
Ert Sah TV<br />
ETV Manisa<br />
EuroStar<br />
Finans Turk TV<br />
Gaziantep Olay Tv<br />
GRT<br />
Guneydoğu TV<br />
GSTV<br />
Haber 61<br />
Hunat TV<br />
IBB TV<br />
Icel TV<br />
Istanbul Metropolitan Municipality<br />
Kanal 3<br />
KANAL<br />
Kanal 7 HD<br />
Kanal 12<br />
Kanal 15<br />
Kanal 23<br />
Kanal 26<br />
Kanal 68<br />
Kanal Alanya<br />
Kanal D HD<br />
Kanal Ege<br />
Kanal Firat<br />
Kanal Z<br />
Kent Deha TV<br />
Kent TV<br />
Kon TV<br />
Kral Pop<br />
Kral TV<br />
Kudüs TV<br />
Lale TV Kırım Tatar<br />
Life TV<br />
Mar TV<br />
Mavi Karadeniz TV<br />
Mercan TV<br />
Meteoroloji TV<br />
Milyon TV<br />
ON4 TV<br />
Öncü TV<br />
Rudaw<br />
Sat7 Türk<br />
Semerkand TV<br />
Sinop Yildiz TV<br />
TRT EBA Ilkokul<br />
TRT EBA Ortaokul<br />
TRT EBA Lise<br />
TRT Muzik<br />
      
                </p>
              </div>
            </div>
            <div className='col'>
              <div className='home__hero-img-wrapper'>
              </div>
            </div>
          </div>
          <div className='row home__hero-row'> 
          </div>
        </div>
      </div>
    </>
  );
}

export default TurkishChannels;
