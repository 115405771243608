import React from 'react';
import './HeroSection.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import Typical from 'react-typical';

function HeroSection({
  lightBg,
  topLine,
  lightText,
  lightTextDesc,
  headline,
  description,
  buttonLabel,
  img,
  alt,
  imgStart
}) {
  return (
    <>
      <div
        className={lightBg ? 'home__hero-section' : 'home__hero-section darkBg'}
      >
        <div className='container'>
          <div
            className='row home__hero-row'
            style={{
              display: 'flex',
              flexDirection: imgStart === 'start' ? 'row-reverse' : 'row'
            }}
          >
            <div className='col'>
              <div className='home__hero-text-wrapper'>
                <div className='top-line'>
                  The world's best IPTV App
                </div>
                <h1 className={lightText ? 'heading' : 'heading dark'}>
                  Watch
                  <Typical
                    steps={['Movies.', 1000, 'Series.', 1000
                    , 'Soccer.', 1000, 'Tennis.', 1000
                    , 'Basketball.', 1000, 'Hockey.', 1000
                    , 'Football.', 1000, 'Baseball.', 1000
                    , 'in 4K.', 1000]}
                    loop={Infinity}
                    wrapper="p"
                  />  
                </h1>
                <p
                  className={
                    lightTextDesc
                      ? 'home__hero-subtitle'
                      : 'home__hero-subtitle dark'
                  }
                >
                  {description}
                </p>
                <Link to='/activation'>
                  <Button buttonSize='btn--wide' buttonColor='blue'>
                    {buttonLabel}
                  </Button>
                </Link>
              </div>
            </div>
            <div className='col'>
              <div className='home__hero-img-wrapper'>
                <img src={img} alt={alt} className='home__hero-img' />
              </div>
            </div>
          </div>
          <div className='row home__hero-row'> 
            <div class="center">
              <img src="images/stv.jpg" className='images_start marginRight' width="240px" height="132px"/>
              <img src="images/atv.png"  className='images_start marginRight' width="240px" height="132px"/>
              <a href="http://app.onpointiptvapp.com/"><img src="images/fire.png"  className='images_start marginRight' width="240px" height="132px"/></a>
              <img src="images/lg.png"  className='images_start marginRight' width="240px" height="132px"/>
            </div>
          </div>
          <br />
        </div>
      </div>
    </>
  );
}

export default HeroSection;
