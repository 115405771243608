import React from 'react';
import { Button } from '../../Button';
import { Link } from 'react-router-dom';
import '../../HeroSection.css';

import 'react-accessible-accordion/dist/fancy-example.css';

function Playlists() {
  return (
    <>
      <div className='home__hero-section'>
        <div className='container'>
          <div className='row home__hero-row'
            style={{
              display: 'flex',
              flexDirection: 'start'
            }}
          >
            <div className='col'>
              <div className='home__hero-text-wrapper'>
                <div className='top-line'>
                Free TV Turkish IPTV Playlist!
                </div>
                <h1 className='heading dark'>  
                  Playlists
                </h1>
                <div className='top-line'>
                
                </div>
                <p className='home__hero-subtitle dark'>
                  <div class="card">
                    <div class="card-head">
                      <div class="product-detail">Turkish Playlist
                      </div>
                      <span class="back-text">
                      </span>
                    </div>
                    <div class="card-body">
                      <div class="product-desc">
                        <span class="product-title">
                          Turkish Playlists <br />over 100 channels
                          <span class="badge">
                            New
                          </span>
                        </span>
                        <span class="product-caption">
                          <img className='images_start' src="https://www.onpointiptvapp.com/images/turkish.png" width="150"/>
                        </span><br />
                        <span className='margin-left'>
                          <Link to='/turkishList' target="_self" >
                            <Button buttonSize='btn--wide' buttonColor='blue'>Get Playlist</Button>
                          </Link>
                        </span>
                        </div>
                        <span class="product-rating">
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star grey"></i>
                        </span>
                      </div>
                      <div class="product-properties">
                        
                    </div>
                    
                  </div>
                  
                </p>
                <p className='home__hero-subtitle dark'>
                  <div class="card">
                    <div class="card-head">
                      <div class="product-detail">Albania Playlist
                      </div>
                      <span class="back-text">
                      </span>
                    </div>
                    <div class="card-body">
                      <div class="product-desc">
                        <span class="product-title">
                          Albania Playlists <br />over 40 channels
                          <span class="badge">
                            New
                          </span>
                        </span>
                        <span class="product-caption">
                          <img className='images_start' src="https://www.onpointiptvapp.com/images/albania.png" width="150"/>
                        </span><br />
                        <span className='margin-left'>
                          <Link to='/albaniaList' target="_self" >
                            <Button buttonSize='btn--wide' buttonColor='blue'>Get Playlist</Button>
                          </Link>
                        </span>
                        </div>
                        <span class="product-rating">
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star grey"></i>
                        </span>
                      </div>
                      <div class="product-properties">
                        
                    </div>
                    
                  </div>
                  
                </p>
              </div>
              <div className='home__hero-img-wrapper dark'>
              </div>
            </div>
            <div className='col'>
              <div className='home__hero-img-wrapper dark'>
              </div>
            </div>
          </div>
          <div className='row home__hero-row dark'> 
            
          </div>
        </div>
      </div>
    </>
  );
}

export default Playlists;
