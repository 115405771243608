import React from 'react';
import '../../HeroSection.css';

function Complete() {

  return (
    <>
      <div className='home__hero-section'>
        <div className='container'>
          <div className='row home__hero-row'
            style={{
              display: 'flex',
              flexDirection: 'start'
            }}
          >
            <div className='col'>
              <div className='home__hero-text-wrapper'>
                <div className='top-line'>
                SUCCESSFULLY ACTIVATED APP
                </div>
                <h1 className='heading dark'>  
                Thank you!
                </h1>
                <div className='top-line'>
                
                </div>
                <p className='home__hero-subtitle dark'>
                  Thank you for purchasing our OnPoint IPTV app.<br/><br/>
                  We will to help you set up the app. Please go through the tutorial below. Please let us know if we can do anything else to help!<br/>
                </p>
              </div>
            </div>
            <div className='col'>
              
            </div>
            <div className='col'>

              <div className='home__hero-text-wrapper'>
                <div className='top-line'>
                How it works
                </div>
                <h1 className='heading dark'>  
                Step 1
                </h1>
                <div className='top-line'>
                
                </div>
                <p className='home__hero-subtitle dark'>
                    1. Check your MAC
                </p>
              </div>
              <div className='home__hero-img-wrapper dark'>
              </div>
            </div>
            <div className='col'>
              <div className='home__hero-img-wrapper dark'>
                <img src="images/step1.jpg"></img>      
              </div>
            </div>


            <div className='col'>
              <div className='home__hero-text-wrapper'>
                <div className='top-line'>
                </div>
                <h1 className='heading dark'>  
                Step 2
                </h1>
                <div className='top-line'>
                
                </div>
                <p className='home__hero-subtitle dark'>
                    1. Click on "Activation"<br/>
                    2. Enter your Email<br/>
                    3. Enter your MAC or LG-ID<br/>
                    4. Click on "Activate App" and activate the App<br/>
                </p>
              </div>
              <div className='home__hero-img-wrapper dark'>
              </div>
            </div>
            <div className='col'>
              <div className='home__hero-img-wrapper dark'>
                <img src="images/step2.jpg"></img>      
              </div>
            </div>

            <div className='col'>
              <div className='home__hero-text-wrapper'>
                <div className='top-line'>
                </div>
                <h1 className='heading dark'>  
                Step 3
                </h1>
                <div className='top-line'>
                
                </div>
                <p className='home__hero-subtitle dark'>
                    1. Enter your MAC<br/>
                    2. Enter your Playlist-URL<br/>
                    3. Press on "Upload Playlist"<br/>
                </p>
              </div>
              <div className='home__hero-img-wrapper dark'>
              </div>
            </div>
            <div className='col'>
              <div className='home__hero-img-wrapper dark'>
                <img src="images/step3.jpg"></img>      
              </div>
            </div>


            <div className='col'>
              <div className='home__hero-text-wrapper'>
                <div className='top-line'>
                </div>
                <h1 className='heading dark'>  
                Step 4
                </h1>
                <div className='top-line'>
                
                </div>
                <p className='home__hero-subtitle dark'>
                    1. Open your App on your TV<br/>
                    2. Press on "Login" or if the App restarts you just have to wait<br/>
                    3. Finished :)<br/>
                </p>
              </div>
              <div className='home__hero-img-wrapper dark'>
              </div>
            </div>
            <div className='col'>
              <div className='home__hero-img-wrapper dark'>
              </div>
            </div>


          </div>
          <div className='row home__hero-row dark'> 
            
          </div>
        </div>
      </div>
    </>
  );
}

export default Complete;
