import React from "react";
import "../../HeroSection.css";

function Legal() {
  return (
    <>
      <div className="home__hero-section">
        <div className="container">
          <div
            className="row home__hero-row"
            style={{
              display: "flex",
              flexDirection: "start",
            }}
          >
            <div className="col">
              <div className="home__hero-text-wrapper">
                <div className="top-line">Legal Disclosure</div>
                <p className="home__hero-subtitle dark">
                  Information in accordance with Section 5 TMG
                  <br />
                  <br />
                  Isidev GmbH
                  <br />
                  Bergmannstr. 191A
                  <br />
                  45886 Gelsenkirchen
                  <br />
                </p>
                <div className="top-line">Represented by</div>
                <p className="home__hero-subtitle dark">Can Isikli</p>
                <div className="top-line">Contact Information</div>
                <p className="home__hero-subtitle dark">
                  Telephone: +4915226009483
                  <br />
                  E-Mail: info@isi-soft.com
                </p>
                <div className="top-line">Register entry</div>
                <p className="home__hero-subtitle dark">
                  Entry in: Handelsregister
                  <br />
                  Register Number: HRB 15818
                  <br />
                  Register Court: Amtsgericht Gelsenkirchen
                  <br />
                </p>
                <div className="top-line">VAT number</div>
                <p className="home__hero-subtitle dark">
                  VAT indentification number in accorance with Section 27 a of
                  the German VAT act
                  <br />
                  DE332868714
                </p>
                <div className="top-line">Disclaimer</div>
                <p className="home__hero-subtitle dark">
                  Accountability for content
                  <br />
                  <br />
                  The contents of our pages were created with the utmost care.
                  We cannot, accept any liability for the correctness,
                  completeness and topicality of the contents. In accordance
                  with legal regulations, we are also responsible for our own
                  content on these web pages. Please note rin this context that
                  we are not obliged to monitor transmitted or stored
                  third-party information or to investigate circumstances that
                  indicate illegal activity. Obligations to remove or block the
                  use of information in accordance with general laws remain
                  unaffected in accordance with §§ 8 to 10 TMG.
                  <br />
                  <br />
                  <br />
                  Accountability for links
                  <br />
                  <br />
                  Liability for links Our site contains links to external
                  websites, over which we have no control. Therefore we cannot
                  accept any responsibility for this external content. The
                  respective provider or operator is always responsible for the
                  contents of any Linked Site. A permanent control of the linked
                  pages is unreasonable without concrete evidence of a violation
                  <br />
                  <br />
                  <br />
                  Copyright
                  <br />
                  <br />
                  Our web pages and their contents are subject to German
                  copyright law. Unless expressly permitted by law, every form
                  of utilizing, reproducing or processing works subject to
                  copyright protection on our web pages requires the prior
                  consent of the respective owner of the rights. Individual
                  reproductions of a work are only allowed for private use. The
                  materials from these pages are copyrighted and any
                  unauthorized use may violate copyright laws.
                </p>
              </div>
            </div>
            <div className="col">
              <div className="home__hero-img-wrapper"></div>
            </div>
          </div>
          <div className="row home__hero-row"></div>
        </div>
      </div>
    </>
  );
}

export default Legal;
