import React, { useEffect } from 'react';
import { Button } from '../../Button';
import { Textbox } from 'react-inputs-validation';
import '../../HeroSection.css';
import { PayPalButton } from "react-paypal-button-v2";
import $ from "jquery";

import 'react-accessible-accordion/dist/fancy-example.css';

function AlbaniaList() {

  useEffect(() => {
    $(".paypal-buttons").hide();
  });

  function clear(){
    $(".paypal-buttons").hide();
  }

  function paypalBuy(){
    var emailInput = $("#emailInput").val();

    if(emailInput){
        $(".addMacInfo").text("");
        $(".addMacInfo").hide();
        $(".paypal-buttons").show();
    }else{
        $(".addMacInfo").text("Please enter your Email");
        $(".addMacInfo").show();
        $(".paypal-buttons").hide();  
    }
  }



  return (
    <>
      <div className='home__hero-section'>
        <div className='container'>
        <div className='row home__hero-row'
            style={{
              display: 'flex',
              flexDirection: 'start'
            }}
          >
            <div className='col'>
              <div className='home__hero-text-wrapper'>
                <div className='top-line'>
                FREE TV Albania IPTV PLAYLIST!
                </div>
                <div className='top-line'>
                <s>13,99 €</s>
                </div>
                <h1 className='heading dark'>  
                9,99 €
                </h1>
                <div className='top-line'>
                
                </div>
                <p className='home__hero-subtitle dark'>
                <div class="textInput">Email address</div>
                    <Textbox
                      attributesInput={{
                        id: 'emailInput',
                        name: 'MAC or LG-ID',
                        type: 'email',
                        placeholder: '',
                      }}
                      onChange={clear}
                    />
                  <Button buttonSize='btn--wide' buttonColor='blue' onClick={paypalBuy}>
                    Buy Playlist
                  </Button><br /><br />
                  <div class="addMacInfo textInput marginBottom marginTop"></div>
                  <PayPalButton
                    amount="9.99"
                    // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                    onSuccess={(details, data) => {
            
                      var email = $("#emailInput").val();
                      var transId = data.orderID;
                      var link = "http://www.onpointiptvapp.com/7546/5875412.m3u";
                      var language = "albania"

                      $.ajax({
                        url: "https://www.onpointiptvapp.com/php/buyPlaylist.php",
                        type: "POST",
                        data: {
                          "email": email,
                          "transId": transId,
                          "language": language,
                          "link": link
                        },
                        success: function (data) {
                          window.location.href = "https://www.onpointiptvapp.com/complete";
                        }
                      });
                    }}
                    options={{
                      clientId: "AUZ-e42QA2dZerkiYGqwceaKprUTDYU7wjlAMu4DIOFPTLNW7z90Bji6weKAWeqqPyq7RMQk3sfdloJt",
                      currency: "EUR",
                      shippingPreference: "GET_FROM_FILE"
                    }}
                  />
                </p>
              </div>
              <div className='home__hero-img-wrapper dark'>
              </div>
            </div>
            <div className='col'>
              <div className='home__hero-img-wrapper dark'>
                <img className='images_start marginRight' src="https://www.onpointiptvapp.com/images/albania.png" width="250"></img>      
              </div>
            </div>


            <div className='col'>
              <div className=''>

                <p className='dark'>
                <h2>Description</h2>
                <br />
                  If you have a Samsung, LG or Android TV with the OnPoint IPTV app, you can install the app yourself on your TV and insert the playlist you bought from us.
                <br /> <br />
                  There are currently over 100 channels available. Subject to changes!
                  <br /> <br />
                  It is important that you have at least a 16.000 Mbits-Ethernet. If you have children who play Playstation or online games, you should have at least a 30.000 Mbits-Ethernet because games affect the streaming quality.
                  <br /> <br />
                  You do not pay for the free TV channels! You pay for the service that we create a playlist for you.
                  <br /> <br />
                  Important: We do not deliver illegal streams like LIG TV ,SKY or DSmart packages.
                  <br /> <br />
                </p>
              </div>
              
            </div>
            



          </div>
        </div>
      </div>
    </>
  );
}
export default AlbaniaList;
